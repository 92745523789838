require([
    "jquery",
    "accessibility",
    "tooltip",
    "formValidation",
    "validation/form_submit_activation_module",
    "modules/login_default_remember_user_module",
    "ibLightModule",
    "fingerprint",
    "jscpConfig",
    "debug",
    "dtAgentConfig",
    "errorHandler"], function ($, accessibilityModule, toolTip, formValidation, formInputActivation, rememberUser, ibLightModule, fingerprint, jscpConfig, debug, dtAgentConfig, errorHandler) {
        var _$ = $;
        var _accessibility = accessibilityModule;
        var _toolTip = toolTip;
        var _formValidation = formValidation;
        var _formInputActivation = formInputActivation;
        var _rememberUser = rememberUser;
        var _ibLightModule = ibLightModule;
        var _jscpConfig = jscpConfig;
        var _debug = debug;
        var _fingerprint = fingerprint;
        var _dtAgentConfig = dtAgentConfig;
        var _errorHandler = errorHandler;

        _$(window).resize(function () {
            _formInputActivation.activateSubmit(_$("form"), "required", true);
        });

        _$(document).ready(function () {
            _debug.parseDebug(window.IsamDebug);

            _ibLightModule.initialize();
            _errorHandler.initialize();

            _$("[data-required]").on("keyup", function () {
                _formInputActivation.activateSubmit(_$("form"), "required", true);
            });

            _$("[data-required]").on("paste input", function (event) {
                event.preventDefault();
                var pastedData = event.originalEvent.clipboardData.getData("text");
                if (pastedData !== "") {
                    _$(this).val(pastedData);
                };
                _formInputActivation.activateSubmit(_$("form"), "required", true);
            });

            _fingerprint.initialize();
            _accessibility.notificationBox(["serverSideError_event"]);
            _rememberUser.initialize(_$("form"), "#username", "#remember-me");
            _rememberUser.rememberUser(_$("form"), "#username", "#remember-me");
            _accessibility.focusAccessible();
            _toolTip.initialize();
            _formValidation.initialize();
            _jscpConfig.initialize();
            _dtAgentConfig.initialize();

            var serverErrorMessage = _$(".validation-msg__server-text .title").text() + _$(".validation-msg__server-text .description").text();

            if (serverErrorMessage !== "") {
                _$(document).trigger("serverSideError_event", [{
                    type: "assertive",
                    message: serverErrorMessage
                }]);
            }
        });
    });